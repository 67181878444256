const envConfig = {
  REACT_APP_API_TOKEN: process.env.REACT_APP_API_TOKEN ?? '#{REACT_APP_API_TOKEN}#',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL ?? 'https://meca-api-prod.azurewebsites.net/api/',
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? '#{REACT_APP_CLIENT_ID}#',
  REACT_APP_HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://meca-api-prod.azurewebsites.net/',
  REACT_APP_GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyCbyH9QdelpvTas84vne4P4dffXrGXjVtE',
  REACT_APP_PREFIX: process.env.REACT_APP_PREFIX ?? 'grocefy-meca',

  REACT_APP_DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE ?? '#{REACT_APP_DEFAULT_LANGUAGE}#',

  REACT_APP_SERVER_CONFIG: process.env.REACT_APP_SERVER_CONFIG ?? 'Endpoint=https://meca-api-prod.azconfig.io;Id=PhD9-l6-s0:IdtAOGOwmfga0nP3MGBw;Secret=gbSdHRwRq7Fx/KaPvN/xjMKROe7cYyMoCNYxapaiCyI=',
  REACT_APP_APP_CONFIG: process.env.REACT_APP_APP_CONFIG ?? 'Endpoint=https://meca-app.azconfig.io;Id=bsLZ-l6-s0:23tNFweuKGM+hinHNlTy;Secret=YwSYOzU9E23Ib1KBN1K6J8d6iHnf3DA66fcRKwANXKU=',
  REACT_APP_MANAGER_CONFIG: process.env.REACT_APP_MANAGER_CONFIG ?? 'Endpoint=https://meca-manager.azconfig.io;Id=h2kd-l6-s0:mn1mMn9g3zb6KU2XX9R2;Secret=qDIyXspX0KmM9RDPwQjF6kFHvWGrJEg4eyNDrJO05gY=',

  REACT_APP_IMAGES_URL: process.env.REACT_APP_IMAGES_URL ?? 'https://grocefy-dev.azure-api.net/images/',
  REACT_APP_IMAGES_KEY: process.env.REACT_APP_IMAGES_KEY ?? '1043159d43f1465bba872d74fe9b6a4e',
  REACT_APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? 'utility-b60bbed139054e2289dfa5d29e4c63aab495f236d3af48168378c41dc671d643',
  REACT_APP_PAYMENTS_KEY: process.env.REACT_APP_PAYMENTS_KEY ?? '9f09ee1311b041afad4a09b53bce41022e952f1576be4933b63a3af9e1860745bf6642961a6e45aba8f05363ca875473c6a4b854e1ab40e19c79b033b3f5a17e',
  REACT_APP_BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? 'https://meca-booking-prod.azurewebsites.net/api/',
  REACT_APP_BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  REACT_APP_TIMEZONE_OFFSET: process.env.REACT_APP_TIMEZONE_OFFSET ?? '-4',
  REACT_APP_BOOKING_CANCELLATION_FEE: process.env.REACT_APP_BOOKING_CANCELLATION_FEE ?? '15',
  BookingEventUrl: process.env.REACT_APP_BOOKING_API_EVENT_URL ?? 'https://www.mecacomplex.com/event',
  SpecificProductUrl: process.env.REACT_APP_SPECIFIC_PRODUCT_URL ?? 'https://www.mecacomplex.com/product',
  WebsiteUrl: process.env.REACT_APP_WEBSITE_URL ?? 'en'
};

export default envConfig;
